import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71e58cb7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "department"
}
const _hoisted_2 = { class: "department__img-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "department__text-container" }
const _hoisted_5 = { class: "department__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `department?id=${_ctx.department.id}`
  }, {
    default: _withCtx(() => [
      (_ctx.department)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "department__img",
                src: _ctx.departmentImage,
                alt: ""
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.department.name_ru_short), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["to"]))
}