
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'UserCard',
  props: {
    user: Object,
    role: String,
  },
  data() {
    return {
      UPLOAD_URL: 'https://back.uib.kz/uploads/',
      user_id: this.user?.user_id || this.user?.id
    }
  }
});
