<template>
  <div>
    <div class="content" v-if="user.nir.length > 0">
      <h1 class="content__title"><strong>Научно-исследовательские работы</strong></h1>

      <div class="content__book" v-for="(book, index) in user.nir" :key="index">
        <p class="content__name"><strong>№{{ index + 1 }}. {{ book.book_name }}</strong></p>
        <p class="content__publish-house">Дом публикации: {{ (book.publishing_house && book.publishing_house !== 'undefined') ? book.publishing_house : '?' }}</p>
        <p class="content__country">Страна: {{ book.country }}</p>
      </div>
    </div>
    <div v-else>
      <h1 class="content__title"><strong>Научно-исследовательские работы отсутствуют</strong></h1></div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
}
</script>

<style lang="scss" scoped>


.content {
  &__title {
    margin: 10px 0 10px 0;
    font-size: 16px;
    text-align: center;
  }

  &__book {
    margin-top: 15px;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }


  @media screen and (min-width: 992px) {
    &__title {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }
  }
}
</style>