<template>
  <div>
    <div class="education">
      <h1 class="education__title" v-if="Object.keys(user.front_discipline).length > 0"><strong>Образование</strong></h1>
      <h1 class="education__title" v-else><strong>Образование отсутствует</strong></h1>
      <div class="education__list row">
        <p class="education__text col-8"><strong>Название</strong></p>
        <p class="education__text col-4"><strong>Период</strong></p>

        <div class="education__item row" v-for="(item, index) in user.education" :key="index">
          <div class="education__text-container col-8">
            <p class="education__text">
              {{ item.education_name }} <br>
              <span class="education__small-text">
              {{ item.education_address }}
            </span>
            </p>
          </div>
          <div class="education__text-container col-4">
            <p class="education__text">{{ item.education_start_year }}-{{ item.education_end_year }}</p>
          </div>
          <div class="education__text-container col-12">
            <p class="education__text-medium">{{ item.education_specialization }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
}
</script>

<style lang="scss" scoped>
.education {
  p {
    font-weight: 500;
  }

  &__list {
    padding: 0;
    margin: 10px 0 0 0;
  }

  &__item {
    margin: 10px 0 0 0;
    padding: 0;
  }

  &__title {
    font-size: 16px;
    margin: 10px 0 10px 0;
    text-align: center;
  }

  &__text {
    font-size: 14px;
    margin: 0;
  }

  &__text-medium {
    font-size: 12px;
  }

  &__small-text {
    font-size: 10px;
  }

  @media screen and (min-width: 992px) {
    &__title {
      font-size: 22px;
    }

    &__text {
      font-size: 18px;
    }

    &__text-medium {
      font-size: 16px;
    }

    &__small-text {
      font-size: 14px;
    }
  }
}
</style>