
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'DepartmentCard',
  props: {
    department: Object,
  },
  computed: {
    departmentImage() {
      const images: Record<number, string> = {
        6: require('@/assets/icons/departments/business.png'),
        7: require('@/assets/icons/departments/social.png'),
        8: require('@/assets/icons/departments/finance.png'),
        9: require('@/assets/icons/departments/language.png'),
        37: require('@/assets/icons/departments/it.png'),
        39: require('@/assets/icons/departments/tourism.png'),
      };

      const departmentId = this.department?.id;
      return departmentId ? images[departmentId] || '' : '';
    }
  }
});
