import httpClient from "@/services/http.service";

import {PpsQueryData} from "@/store/modules/pps-data/ppsData.types";

const ppsDataService = {
    getAdministrations() {
        return httpClient.get('api/administrations/get-administrations')
    },
    getDepartments() {
        return httpClient.get('api/departments/get-main-departments')
    },
    getPpsByDepartment(queryData: PpsQueryData) {
        return httpClient.get(`api/pps-data/get-pps-by-department-id?id=${queryData.id}&page=${queryData.page}&fio=${queryData.fullname}&page_size=${queryData.page_size}`)
    },
    getDepartmentHead(department_id: number) {
        return httpClient.get('api/pps-data/get-department-head?department_id=' + department_id)
    },
    getUserById(user_id: number){
        return httpClient.get('api/pps-data/get-pps-by-user-id?user_id=' + user_id);
    }
}

export default ppsDataService;