
import {defineComponent} from 'vue';
import {mapActions, mapState} from "vuex";
import Navbar from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import UserCard from "@/components/cards/UserCard.vue";
import InfoTab from "@/components/profile/InfoTab.vue";
import EducationTab from "@/components/profile/EducationTab.vue";
import DisciplinesTab from "@/components/profile/DisciplinesTab.vue";
import ScheduleTab from "@/components/profile/ScheduleTab.vue";
import ScienceTab from "@/components/profile/ScienceTab.vue";
import CertificateTab from "@/components/profile/CertificateTab.vue";

export default defineComponent({
  name: 'ProfileView',
  components: {
    Footer, Navbar, UserCard,
    InfoTab, EducationTab, DisciplinesTab, ScheduleTab, ScienceTab, CertificateTab
  },
  data() {
    return {
      is_found_user: true,
      UPLOAD_URL: 'https://back.uib.kz/uploads/',
      preLoader: true,
    }
  },
  computed: {
    ...mapState('ppsData', ['departments', 'user']),

  },
  methods: {
    ...mapActions('ppsData', ['GET_DEPARTMENTS', 'GET_USER_BY_ID']),

    openTab(evt: MouseEvent, tabName: string): void {
      const tabcontent = document.getElementsByClassName("info__tab-content") as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      const tablinks = document.getElementsByClassName("info__tab-link") as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].classList.remove("active");
      }

      const tabElement = document.getElementById(tabName);
      if (tabElement) {
        tabElement.style.display = "block";
      }

      const currentTarget = evt.currentTarget as HTMLElement;
      currentTarget.classList.add("active");
    }
  },
  async mounted() {
    await this.GET_DEPARTMENTS();
    if (!await this.GET_USER_BY_ID(this.$route.query.id)) {
      this.is_found_user = false;
    }
    this.preLoader = false;
  },
});
