
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Header',
  props: {
    departments: Array,
  },
  data(){
    return {
      windowWidth: window.innerWidth,
      showDropdown: false,
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
});
