
import {defineComponent, PropType} from 'vue';
import Expander from "@/components/Expander.vue";

export default defineComponent({
  name: 'DisciplinesTab',
  components: {
    Expander
  },
  props: {
    user: {
      type: Object as PropType<{ front_discipline: any[] }>,
      required: true
    }
  },
  data() {
    return {
      open: false,
      animation: 'rightToLeft'
    }
  }
});
