
import {defineComponent} from 'vue';
import {mapActions, mapMutations, mapState} from "vuex";

import Navbar from "@/components/Header.vue";
import {DepartmentData} from "@/store/modules/pps-data/ppsData.types";
import UserCard from "@/components/cards/UserCard.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: 'DepartmentView',
  components: {
    Footer,
    UserCard,
    Navbar,
  },
  data() {
    return {
      preLoader: true,
      ppsQueryData: {
        id: Number(this.$route.query.id),
        page: 1,
        fullname: '',
        page_size: 11,
      },
      inputQuery: '',
    }
  },
  watch: {
    async inputQuery(newValue) {
      if (newValue.length >= 3) {
        this.ppsQueryData.fullname = newValue
        await this.GET_PPS_BY_DEPARTMENT(this.ppsQueryData);
      } else if (newValue.length == 0) {
        this.ppsQueryData.fullname = '';
        await this.GET_PPS_BY_DEPARTMENT(this.ppsQueryData);
      }
    }
  },
  computed: {
    ...mapState('ppsData', ['departments', 'ppsList', 'administrations', 'departmentHead']),

    currentDepartmentName(): string {
      if (this.$route.query.id == 'admin') {
        return '«Администрация»';
      }
      return this.departments.find((department: DepartmentData) => department.id == this.$route.query.id)?.name_ru_short;
    }
  },
  methods: {
    ...mapActions('ppsData', ['GET_DEPARTMENTS', 'GET_PPS_BY_DEPARTMENT', 'GET_ADMINISTRATIONS', 'GET_DEPARTMENT_HEAD']),
    ...mapMutations('ppsData', ['SET_EMPTY_STATES']),

    async handlePageChange(event: any) {
      this.ppsQueryData.page = event.page + 1;
      await this.GET_PPS_BY_DEPARTMENT(this.ppsQueryData);
    }
  },
  async mounted() {
    await this.GET_DEPARTMENTS();
    await this.SET_EMPTY_STATES();

    if (this.$route.query.id == 'admin') {
      await this.GET_ADMINISTRATIONS()
    } else {
      await this.GET_PPS_BY_DEPARTMENT(this.ppsQueryData);
      await this.GET_DEPARTMENT_HEAD(this.$route.query.id);
    }
    this.preLoader = false;
  },

  async beforeRouteUpdate(to, from, next) {
    if (to.query.id !== this.$route.query.id) {
      this.preLoader = true;
      await this.SET_EMPTY_STATES();

      if (to.query.id === 'admin') {
        await this.GET_ADMINISTRATIONS();
      } else {
        this.ppsQueryData.id = Number(to.query.id);
        await this.GET_PPS_BY_DEPARTMENT(this.ppsQueryData);
        await this.GET_DEPARTMENT_HEAD(to.query.id);
      }

      this.preLoader = false;
    }
    next();
  }
});
