import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'primeicons/primeicons.css';


// Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

// PrimeVue
import 'primevue/resources/themes/saga-green/theme.css'
import PrimeVue from 'primevue/config';
import ProgressSpinner from 'primevue/progressspinner';
import Paginator from 'primevue/paginator';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

const app = createApp(App)

app.use(PrimeVue)
    .use(store)
    .use(router)
    .mount('#app')

app.component('PreLoader', ProgressSpinner)
    .component('Paginator', Paginator)
    .component('Accordion', Accordion)
    .component('AccordionTab', AccordionTab)