import ppsDataService from "@/services/pps-data/ppsData.service";

import {Commit} from "vuex";
import {AdminData, DepartmentData, DepartmentHeadData, PpsDataState, PpsQueryData}
    from "@/store/modules/pps-data/ppsData.types";

const ppsData = {
    namespaced: true,
    actions: {
        async GET_ADMINISTRATIONS({commit}: { commit: Commit }) {
            try {
                const {data} = await ppsDataService.getAdministrations();
                if (data) {
                    commit('SET_ADMINISTRATIONS', data);
                }
                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async GET_DEPARTMENTS({commit}: { commit: Commit }) {
            try {
                const {data} = await ppsDataService.getDepartments();
                if (data) {
                    commit('SET_DEPARTMENTS', data);
                }
                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async GET_PPS_BY_DEPARTMENT({commit}: { commit: Commit }, queryData: PpsQueryData) {
            try {
                const {data} = await ppsDataService.getPpsByDepartment(queryData);
                commit('SET_PPS_LIST', data);
                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async GET_DEPARTMENT_HEAD({commit}: { commit: Commit }, department_id: number) {
            try {
                const {data} = await ppsDataService.getDepartmentHead(department_id);
                commit('SET_DEPARTMENT_HEAD', data);
                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async GET_USER_BY_ID({commit}: {commit: Commit}, user_id: number){
            try {
                const {data} = await ppsDataService.getUserById(user_id);
                commit('SET_USER_BY_ID', data);
                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        }
    },
    mutations: {
        SET_ADMINISTRATIONS(state: PpsDataState, data: AdminData[]): void {
            state.administrations = data;
        },
        SET_DEPARTMENTS(state: PpsDataState, data: DepartmentData[]): void {
            state.departments = data;
        },
        SET_PPS_LIST(state: PpsDataState, data: any): void {
            state.ppsList = data;
        },
        SET_DEPARTMENT_HEAD(state: PpsDataState, data: DepartmentHeadData[]): void {
            state.departmentHead = data;
        },
        SET_EMPTY_STATES(state: PpsDataState): void {
            state.administrations = [];
            state.ppsList = {};
            state.departmentHead = [];
        },
        SET_USER_BY_ID(state: PpsDataState, data: any): void {
            state.user = data[0];
        }
    },
    state: {
        administrations: [],
        departments: [],
        ppsList: {},
        departmentHead: [],
        user: {}
    },
}

export default ppsData;