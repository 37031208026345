<template>
  <div>
    <div class="content row">
      <h1 class="content__title col-12"><strong>Основная информация</strong></h1>
      <div class="content__text-container col-4">
        <p class="content__text">ФИО</p>
      </div>
      <div class="content__text-container col-8">
        <p class="content__text">{{ user.lastname }} {{ user.firstname }} {{ user.middlename }}</p>
      </div>
      <div class="content__text-container col-4">
        <p class="content__text">Учёная степень</p>
      </div>
      <div class="content__text-container col-8">
        <p class="content__text">{{ user.science_degree }}</p>
      </div>
      <div class="content__text-container col-4">
        <p class="content__text">Почта</p>
      </div>
      <div class="content__text-container col-8">
        <p class="content__text">{{ user.email }}</p>
      </div>
      <div class="content__text-container col-4">
        <p class="content__text">Языковые навыки</p>
      </div>
      <div class="content__text-container col-8">
        <div>
          <p class="content__text" v-for="(language, index) in user.language_skill">
            {{ language.language }} {{ language.language_level }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0 10px 0 10px;

  p {
    font-weight: 500;
  }

  &__title {
    font-size: 16px;
    text-align: center;
    margin: 10px 0 10px 0;
  }

  &__text-container {
    display: flex;
    align-items: center;

    &:nth-child(n+2) {
      margin-top: 20px;
    }
  }

  &__text {
    display: block !important;
    margin: 0;
    font-size: 14px;
  }

  @media screen and (min-width: 992px) {
    &__title {
      font-size: 22px;
    }

    &__text {
      font-size: 18px;
    }
  }
}
</style>