
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Expander',
  props: {
    title: {
      type: String,
      default: 'title'
    },
    animation: {
      type: String,
      default: 'bottomToTop'
    }
  },
  data() {
    return {
      open: false
    }
  }
});
