import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-555e1110"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center min-vh-100"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "department" }
const _hoisted_6 = {
  key: 0,
  class: "department__input-container"
}
const _hoisted_7 = {
  key: 1,
  class: "department__title"
}
const _hoisted_8 = {
  key: 2,
  class: "department"
}
const _hoisted_9 = {
  key: 0,
  class: "department__none"
}
const _hoisted_10 = { class: "department__users row" }
const _hoisted_11 = {
  key: 0,
  class: "department"
}
const _hoisted_12 = {
  key: 0,
  class: "department__paginator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreLoader = _resolveComponent("PreLoader")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_UserCard = _resolveComponent("UserCard")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_ctx.preLoader)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PreLoader)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_Navbar, { departments: _ctx.departments }, null, 8, ["departments"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("main", _hoisted_5, [
              (_ctx.ppsList.query)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _withDirectives(_createElementVNode("input", {
                      class: "department__search",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputQuery) = $event)),
                      type: "text",
                      placeholder: "Поиск преподавателя..."
                    }, null, 512), [
                      [_vModelText, _ctx.inputQuery]
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.inputQuery.length < 3)
                ? (_openBlock(), _createElementBlock("h1", _hoisted_7, _toDisplayString(_ctx.currentDepartmentName), 1))
                : _createCommentVNode("", true),
              (_ctx.ppsList.query)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_ctx.ppsList.query.length < 1)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_9, "Преподаватель не найден..."))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.administrations, (user, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "department__user col-12 col-sm-6 col-md-4 col-xl-3",
                    key: index
                  }, [
                    _createVNode(_component_router_link, {
                      class: "href",
                      to: `user?id=${user.user_id}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_UserCard, { user: user }, null, 8, ["user"])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 128)),
                (_ctx.inputQuery.length < 3)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.departmentHead, (user, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "department__user col-12 col-sm-6 col-md-4 col-xl-3",
                        key: index
                      }, [
                        _createVNode(_component_router_link, {
                          class: "href",
                          to: `user?id=${user.id}`
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_UserCard, {
                              user: user,
                              role: 'Заведующий кафедрой'
                            }, null, 8, ["user"])
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]))
                    }), 128))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ppsList.query, (user, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "department__user col-12 col-sm-6 col-md-4 col-xl-3",
                    key: index
                  }, [
                    _createVNode(_component_router_link, {
                      class: "href",
                      to: `user?id=${user.id}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_UserCard, { user: user }, null, 8, ["user"])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 128))
              ])
            ]),
            (_ctx.ppsList.query)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_ctx.ppsList?.query.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createVNode(_component_Paginator, {
                          rows: 11,
                          totalRecords: _ctx.ppsList.pages.totalCount,
                          onPage: _ctx.handlePageChange,
                          template: {
                  '640px': 'PrevPageLink CurrentPageReport NextPageLink',
                  '960px': 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
                  '1300px': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
                  default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink'
                }
                        }, null, 8, ["totalRecords", "onPage"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Footer)
          ])
        ])
      ]))
}