<template>
  <div class="tab schedule" v-if="user.schedule.length > 0">
    <h3 class="schedule__title"><strong>Расписание</strong></h3>
    <div class="schedule__divider"></div>
    <div v-if="windowWidth > 768">
      <div class="row">
        <div v-if="user.schedule.filter(i => i.day_id == 1).length > 0" class="schedule__topic-item col">Понедельник
        </div>
        <div v-if="user.schedule.filter(i => i.day_id == 2).length > 0" class="schedule__topic-item col">Вторник</div>
        <div v-if="user.schedule.filter(i => i.day_id == 3).length > 0" class="schedule__topic-item col">Среда</div>
        <div v-if="user.schedule.filter(i => i.day_id == 4).length > 0" class="schedule__topic-item col">Четверг</div>
        <div v-if="user.schedule.filter(i => i.day_id == 5).length > 0" class="schedule__topic-item col">Пятница</div>
      </div>
      <div class="schedule__divider"></div>
      <div class="row">
        <div class="col" v-if="user.schedule && user.schedule.filter(i => i.day_id == 1).length > 0">
          <div v-for="value in user.schedule.filter(i => i.day_id == 1)">
            <h5>{{ value.time }}</h5>
            <p>{{ value.education_discipline_name }}</p>
            <p>Ауд: {{ value.room }}</p>
          </div>
        </div>
        <div class="col" v-if="user.schedule && user.schedule.filter(i => i.day_id == 2).length > 0">
          <div v-for="value in user.schedule.filter(i => i.day_id == 2)">
            <h5>{{ value.time }}</h5>
            <p>{{ value.education_discipline_name }}</p>
            <p>Ауд: {{ value.room }}</p>
          </div>
        </div>
        <div class="col" v-if="user.schedule && user.schedule.filter(i => i.day_id == 3).length > 0">
          <div v-for="value in user.schedule.filter(i => i.day_id == 3)">
            <h5>{{ value.time }}</h5>
            <p>{{ value.education_discipline_name }}</p>
            <p>Ауд: {{ value.room }}</p>
          </div>
        </div>
        <div class="col" v-if="user.schedule && user.schedule.filter(i => i.day_id == 4).length > 0">
          <div v-for="value in user.schedule.filter(i => i.day_id == 4)">
            <h5>{{ value.time }}</h5>
            <p>{{ value.education_discipline_name }}</p>
            <p>Ауд: {{ value.room }}</p>
          </div>
        </div>
        <div class="col" v-if="user.schedule && user.schedule.filter(i => i.day_id == 5).length > 0">
          <div v-for="value in user.schedule.filter(i => i.day_id == 5)">
            <h5>{{ value.time }}</h5>
            <p>{{ value.education_discipline_name }}</p>
            <p>Ауд: {{ value.room }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Accordion :activeIndex="0">
        <AccordionTab v-if="user.schedule.filter(i => i.day_id == 1).length > 0" header="Понедельник">
          <div class="row">
            <div class="col-6" v-for="value in user.schedule.filter(i => i.day_id == 1)">
              <h5>{{ value.time }}</h5>
              <p>{{ value.education_discipline_name }}</p>
              <p>Ауд: {{ value.room }}</p>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab v-if="user.schedule.filter(i => i.day_id == 2).length > 0" header="Вторник">
          <div class="row">
            <div class="col-6" v-for="value in user.schedule.filter(i => i.day_id == 2)">
              <h5>{{ value.time }}</h5>
              <p>{{ value.education_discipline_name }}</p>
              <p>Ауд: {{ value.room }}</p>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab v-if="user.schedule.filter(i => i.day_id == 3).length > 0" header="Среда">
          <div class="row">
            <div class="col-6" v-for="value in user.schedule.filter(i => i.day_id == 3)">
              <h5>{{ value.time }}</h5>
              <p>{{ value.education_discipline_name }}</p>
              <p>Ауд: {{ value.room }}</p>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab v-if="user.schedule.filter(i => i.day_id == 4).length > 0" header="Четверг">
          <div class="row">
            <div class="col-6" v-for="value in user.schedule.filter(i => i.day_id == 4)">
              <h5>{{ value.time }}</h5>
              <p>{{ value.education_discipline_name }}</p>
              <p>Ауд: {{ value.room }}</p>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab v-if="user.schedule.filter(i => i.day_id == 5).length > 0" header="Пятница">
          <div class="row">
            <div class="col-6" v-for="value in user.schedule.filter(i => i.day_id == 5)">
              <h5>{{ value.time }}</h5>
              <p>{{ value.education_discipline_name }}</p>
              <p>Ауд: {{ value.room }}</p>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
  <div class="schedule" v-else>
    <h1 class="schedule__title"><strong>Расписание отсутствует</strong></h1>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
.schedule {
  &__title {
    font-size: 16px;
    text-align: center;
    margin: 10px 0 10px 0;
  }

  &__divider {
    border-bottom: solid 1px gray;
    margin: 15px 0;
  }

  &__topic-item {
    font-weight: 700;
  }

  @media screen and (min-width: 992px) {
    &__title {
      font-size: 22px;
    }
  }
}
</style>