<template>
  <div>
    <div class="certificate">
      <h1 class="certificate__title"><strong>Сертификаты отсутствуют</strong></h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
}
</script>

<style lang="scss" scoped>
.certificate {
  &__title {
    margin: 10px 0 10px 0;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  @media screen and (min-width: 992px) {
    &__title {
      font-size: 22px;
    }
  }
}
</style>