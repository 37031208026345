
import {defineComponent} from 'vue';
import {mapActions, mapState} from "vuex";

import UserCard from "@/components/cards/UserCard.vue";
import DepartmentCard from "@/components/cards/DepartmentCard.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    UserCard,
    DepartmentCard,
    Footer
  },
  data() {
    return {
      preLoader: true,
    }
  },
  computed: {
    ...mapState('ppsData', ['administrations', 'departments'])
  },
  methods: {
    ...mapActions('ppsData', ['GET_ADMINISTRATIONS', 'GET_DEPARTMENTS']),
  },
  async mounted() {
    await this.GET_ADMINISTRATIONS();
    await this.GET_DEPARTMENTS();
    this.preLoader = false;
  },
});
