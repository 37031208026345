<template>
  <router-view/>
</template>

<style lang="scss">
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}
body {
  font-family: 'Montserrat', sans-serif !important;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}
</style>
<script setup lang="ts">
</script>